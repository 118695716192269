import styled from 'styled-components';

export const CardPicture = styled.picture`
  display: block;
  grid-area: picture;

  img {
    display: block;
    width: 100%;
  }

`;

export const Divider = styled.div`
background-color: #D9D9D9;
height: 70%;
`;

CardPicture.displayName = 'CardPicture';
Divider.displayName = 'Divider';
