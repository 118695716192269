import {
  useCallback, useEffect, useRef, useState,
} from 'react';

import useInView from '@nubank/www-latam-commons/utils/hooks/useInView';

const useAutoScrollTracker = (autoScrollIntervalInMs, nextStep) => {
  const [isAutoScrollActive, setIsAutoScrollActive] = useState(true);

  const [ref, isVisible] = useInView();

  const autoScrollTimerRef = useRef();

  useEffect(() => () => { window.clearInterval(autoScrollTimerRef.current); }, []);

  const startAutoScroll = useCallback(() => {
    setIsAutoScrollActive(true);
  }, []);

  const stopAutoScroll = useCallback(() => {
    setIsAutoScrollActive(false);
  }, []);

  useEffect(() => {
    if (isVisible) {
      startAutoScroll();
    } else {
      stopAutoScroll();
    }
  }, [isVisible, startAutoScroll, stopAutoScroll]);

  useEffect(() => {
    clearInterval(autoScrollTimerRef.current);
    if (isAutoScrollActive) {
      autoScrollTimerRef.current = window.setInterval(nextStep, autoScrollIntervalInMs);
    }
  }, [isAutoScrollActive, nextStep, autoScrollIntervalInMs]);

  return [ref, {
    isAutoScrollActive,
    startAutoScroll,
    stopAutoScroll,
  }];
};

export default useAutoScrollTracker;
