import styled from 'styled-components';

export const FormContainer = styled.div`
  display: block;

  button {
    margin: 0;
    max-width: fit-content
  }

  input {
    max-width: 27rem;
  }

  @media (orientation: portrait) {
    button {
      max-width: 21rem;
    }
  }

`;

FormContainer.displayName = 'FormContainer';
