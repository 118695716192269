import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { CarouselContext } from '../../CarouselContext';

import { ControlIcon } from './styles/ControlIcon';
import { ControlWrapper } from './styles/ControlWrapper';

const CarouselControl = ({
  colorSelected,
  colorUnselected,
  className,
}) => {
  const { currentStep, goToStep, items } = useContext(CarouselContext);

  return (
    <ControlWrapper className={className} width="100%">
      {items.map((item, index) => (
        <ControlIcon
          key={item.key}
          isSelected={index + 1 === currentStep}
          colorSelected={colorSelected}
          colorUnselected={colorUnselected}
          onClick={() => goToStep(index)}
          aria-label={`Go to step ${index + 1}`}
          role="button"

        />
      ))}
    </ControlWrapper>
  );
};

CarouselControl.defaultProps = {
  className: undefined,
  colorSelected: 'primary',
  colorUnselected: [],
};

CarouselControl.propTypes = {
  className: PropTypes.string,
  colorSelected: PropTypes.string,
  colorUnselected: PropTypes.arrayOf(PropTypes.string),
};

export default CarouselControl;
