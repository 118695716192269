import styled, { css } from 'styled-components';
import { nuDSColor } from '@nubank/nuds-web/styles/themeUtils';
import { breakpointsMedia } from '@nubank/nuds-web/styles/breakpoints';

export const MPHeroSignUpForm = styled.div`

    #label-email {
        div {
            ${breakpointsMedia({
    xs: css`white-space: pre-line;`,
    lg: css`white-space: nowrap;`,
  })}
            span {
                font-style: normal;
                font-weight: 500;
                font-size: 24px;
                line-height: 122%;
                letter-spacing: -0.02em;
                font-feature-settings: 'tnum' on, 'lnum' on, 'ss05' on;
                color: ${({ color }) => color};
                padding-bottom: 24px;
                width: 100%;  
            }
        }
    };

    .form-container {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        flex-wrap: wrap;

        div:first-child {
            ${breakpointsMedia({
    xs: css`width: 100%;`,
    lg: css`width: inherit;`,
  })}
        }
    };

    .mp-signup-form-button {
        span {
            padding-right: 5px;
        }
    };

    .mp-signup-form-text {
        font-size: 18px;
        ${breakpointsMedia({
    xs: css`width: 100%`,
    md: css``,
    lg: css`width: 260px;`,
  })}
        padding: 5vh 0 1vh;

        ::placeholder {
            color: ${nuDSColor('black', 'defaultT20')};
        }
    };

    background-color: ${nuDSColor('white', 'default')};
    padding: 24px;
    border-radius: 16px;
    width: 100%;
`;

MPHeroSignUpForm.displayName = 'MPHeroSignUpForm';
