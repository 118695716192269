import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import Box from '@nubank/nuds-web/components/Box/Box';

import FeedbackWrapper from './styles/FeedbackWrapper';

const FeedbackItem = ({ description, owner, location }) => (
  <FeedbackWrapper
    marginBottom={{ xs: 48, lg: 84 }}
    paddingHorizontal={{ xs: 24, lg: 282 }}
  >
    <Typography
      variant="heading4"
      color="black"
      colorVariant="light"
      tag="q"
      intlKey={description}
    />

    <Box
      marginTop={{ xs: 12, md: 48 }}
    >
      <Typography
        variant="subtitle1"
        strong
        color="black"
        colorVariant="defaultT70"
        gutterBottom={0.5}
        intlKey={owner}
      />

      <Typography
        variant="subtitle1"
        strong
        tag="p"
        color="black"
        colorVariant="light"
        intlKey={location}
      />
    </Box>
  </FeedbackWrapper>
);

FeedbackItem.propTypes = {
  description: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  owner: PropTypes.string.isRequired,
};

export default FeedbackItem;
