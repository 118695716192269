import React from 'react';
import PropTypes from 'prop-types';

import useCarousel from '../../utils/hooks/useCarousel';

import { CarouselContextProvider } from './CarouselContext';
import CarouselControl from './components/CarouselControl/CarouselControl';
import CarouselTracker from './CarouselTracker';

const LEFT_OFFSET = 1;

const Carousel = ({
  children,
  items,
  transitionDurationInMs,
  className,
}) => {
  const totalItems = items.length;
  const initialSwipeableItem = totalItems - LEFT_OFFSET;

  const initialState = {
    swipeableStepIndex: initialSwipeableItem,
    stepIndex: 0,
    sliding: false,
  };

  const {
    nextStep,
    prevStep,
    state,
    goToStep,
  } = useCarousel(initialState, totalItems, transitionDurationInMs);

  return (
    <CarouselContextProvider value={{
      prevStep,
      nextStep,
      goToStep,
      state,
      items,
      currentStep: state.stepIndex + 1,
      transitionDurationInMs,
    }}
    >
      <CarouselTracker className={className}>
        {children}
      </CarouselTracker>
    </CarouselContextProvider>
  );
};

Carousel.defaultProps = {
  transitionDurationInMs: '0',
  className: undefined,

};

Carousel.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.func]).isRequired,
  className: PropTypes.string,
  items: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.func]).isRequired,
  transitionDurationInMs: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

Carousel.Control = CarouselControl;

export default Carousel;

