import { useCallback, useReducer } from 'react';

export const PREV = 'PREV';
export const NEXT = 'NEXT';
export const SLIDE_TO = 'SLIDE_TO';
export const STOP_SLIDING = 'STOP_SLIDING';

export function swipeableStepIndexForChildIndex(childIndex, totalChildren) {
  const firstSwipeableItemIndex = totalChildren;

  if (childIndex === 0) {
    return firstSwipeableItemIndex;
  }

  return childIndex;
}

export const getSwipeableCSSSlideOrder = ({ index, swipeableStepIndex, numItems }) => {
  const baseOrder = 0;
  const indexToCSSOrder = index - swipeableStepIndex;
  const isIndexToCSSOrderNegative = indexToCSSOrder < baseOrder;

  if (isIndexToCSSOrderNegative) {
    const positiveIndexToCSSOrder = numItems - Math.abs(indexToCSSOrder);
    return positiveIndexToCSSOrder;
  }

  return indexToCSSOrder;
};

const actions = {
  PREV: (state, { numItems }) => {
    const prevStepIndex = state.stepIndex - 1;
    const prevSwipeableStepIndex = state.swipeableStepIndex === 0
      ? numItems - 1
      : state.swipeableStepIndex - 1;
    const futureStepIndex = prevStepIndex < 0 ? numItems - 1 : prevStepIndex;

    return {
      ...state,
      slideToDirection: PREV,
      sliding: futureStepIndex !== state.stepIndex,
      swipeableStepIndex: prevSwipeableStepIndex,
      stepIndex: futureStepIndex,
    };
  },
  NEXT: (state, { numItems }) => {
    const nextStepIndex = state.stepIndex + 1;
    const futureStepIndex = nextStepIndex >= numItems
      ? 0
      : nextStepIndex;
    const isSwipeableStepIndexEqualFirstChildIndex = state.swipeableStepIndex === numItems - 1;
    const nextSwipeableStepIndex = isSwipeableStepIndexEqualFirstChildIndex
      ? 0
      : state.swipeableStepIndex + 1;

    return {
      ...state,
      slideToDirection: NEXT,
      sliding: futureStepIndex !== state.stepIndex,
      swipeableStepIndex: nextSwipeableStepIndex,
      stepIndex: futureStepIndex,
    };
  },
  SLIDE_TO: (state, { nextSlideNumber, numItems }) => {
    const isNextSlideNumberFirstSwipeableChildIndex = nextSlideNumber === 0;
    const futureSwipeableStepIndex = isNextSlideNumberFirstSwipeableChildIndex
      ? numItems - 1
      : nextSlideNumber - 1;

    const futureDirection = nextSlideNumber > state.stepIndex
      ? NEXT
      : PREV;

    const hasSliding = nextSlideNumber !== state.stepIndex;

    return {
      ...state,
      sliding: hasSliding,
      swipeableStepIndex: futureSwipeableStepIndex,
      slideToDirection: futureDirection,
      stepIndex: nextSlideNumber,
    };
  },
  STOP_SLIDING: state => ({
    ...state,
    sliding: false,
  }),
};

function carouselReducer(state, action = { sliding: false }) {
  const executeAction = actions[action.type] || (() => state);
  return executeAction(state, action);
}

function useCarousel(initialState, totalChildren) {
  const [state, dispatch] = useReducer(carouselReducer, initialState);

  const slide = useCallback((slideToDirection, nextSlideNumber = 0) => {
    dispatch({ type: slideToDirection, numItems: totalChildren, nextSlideNumber });
    setTimeout(() => dispatch({ type: STOP_SLIDING }), 1);
  }, [dispatch]);

  const prevStep = useCallback(() => slide(PREV), [slide]);
  const nextStep = useCallback(() => slide(NEXT), [slide]);
  const goToStep = useCallback(stepNumber => slide(SLIDE_TO, stepNumber), [slide]);

  return {
    state,
    prevStep,
    nextStep,
    goToStep,
  };
}

export default useCarousel;
