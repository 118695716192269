import styled from 'styled-components';
import PropTypes from 'prop-types';

const handleSlideTransition = ({ sliding, slideToDirection, cardWidth }) => {
  if (!sliding) {
    return `translateX(-${cardWidth}%)`;
  }

  if (slideToDirection === 'PREV') {
    return `translateX(calc(2 * -${cardWidth}%))`;
  }

  return 'translateX(0%)';
};

export const CarouselContainer = styled.div`
  display: flex;
  will-change: contents;
  transition: ${props => (props.sliding ? 'none' : `transform ${props.transitionDurationInMs}ms cubic-bezier(0.23, 1, 0.32, 1)`)};
  transform: ${handleSlideTransition};
`;

CarouselContainer.defaultProps = {
  slideToDirection: 'NEXT',
};

CarouselContainer.propTypes = {
  slideToDirection: PropTypes.oneOf(['PREV', 'NEXT']),
  sliding: PropTypes.bool.isRequired,
  transitionDurationInMs: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

CarouselContainer.displayName = 'CarouselContainer';

