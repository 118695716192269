import React, {
  useContext,
} from 'react';
import PropTypes from 'prop-types';

import useAutoScrollTracker from '../../utils/hooks/useAutoScrollTracker';

import { CarouselContext } from './CarouselContext';

const CarouselTracker = ({ children, className, autoScrollIntervalInMs }) => {
  const { nextStep } = useContext(CarouselContext);

  const [ref, {
    startAutoScroll,
    stopAutoScroll,
  }] = useAutoScrollTracker(autoScrollIntervalInMs, nextStep);

  return (
    <div
      className={className}
      onBlur={startAutoScroll}
      onMouseOut={startAutoScroll}
      onFocus={stopAutoScroll}
      onMouseOver={stopAutoScroll}
      onTouchMove={stopAutoScroll}
      ref={ref}
    >
      {children}
    </div>
  );
};

CarouselTracker.defaultProps = {
  autoScrollIntervalInMs: 4000,
  className: undefined,
};

CarouselTracker.propTypes = {
  autoScrollIntervalInMs: PropTypes.number,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.func]).isRequired,
  className: PropTypes.string,
};

export default CarouselTracker;
