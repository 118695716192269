import styled from 'styled-components';
import Box from '@nubank/nuds-web/components/Box/Box';

const FeedbackWrapper = styled(Box)`
  text-align: center;
`;

FeedbackWrapper.displayName = 'FeedbackWrapper';

export default FeedbackWrapper;
