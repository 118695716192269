import styled from 'styled-components';
import { tablet768, desktop1024 } from '@nubank/nuds-web/styles/breakpoints';

export const HeroForm = styled.div`
  padding: 1.5rem;
  order: 3;
  
  ${tablet768`
    padding: 2.5rem;
    flex-grow: 1;
    max-width: calc(100% - 409px);
    width: calc(100% - 409px);
  `}

  ${desktop1024`
    order: 2;
    flex-basis: 45%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 3.5rem;
  `}

`;

HeroForm.displayName = 'HeroForm';
