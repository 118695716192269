import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import Box from '@nubank/nuds-web/components/Box/Box';
import Image from '@nubank/nuds-web/components/Image/Image';
import { nuDSColor } from '@nubank/nuds-web/styles/themeUtils';
import { css } from 'styled-components';

import { Divider } from './styles/CardPicture';

const dividerColumns = { md: '1fr 2px 1fr', lg: '1fr 2px 1fr', xl: '1fr 2px 1fr' };
const defaultColumns = { md: '1fr 1fr', lg: '1fr 1fr', xl: '1fr 1fr' };
const ProductHero = ({
  heroImages,
  alt,
  backGroungColor,
  gridLargeOrder,
  title,
  titleColor,
  content,
  contentColor,
  imageSize,
  hasDivider,
  styleOverride,
  rate,
}) => (
  <Box
    backgroundColor={backGroungColor}
    display="grid"
    tag="section"
    gridTemplateColumns={hasDivider ? dividerColumns : defaultColumns}
    gridTemplateAreas={{
      xs: `"picture"
          "valueProps"`,
      md: `${gridLargeOrder}`,
      lg: `${gridLargeOrder}`,
    }}
    justifyContent="center"
    alignItems="center"
    css={css`${styleOverride}`}
    className="vp-grid-container"
  >

    <Box
      gridArea="valueProps"
      padding={{ xs: '1rem 2rem 1rem', md: '6rem 1rem 6rem', lg: '6rem 8.5rem 6rem' }}
      className="vp-text-container"
    >
      <Typography
        variant="heading2"
        color={titleColor}
        tag="h2"
        intlKey={title}
        css={css`span {color: ${nuDSColor('primary')};}`}
        intlValues={{
          span: msg => (
            <span>{msg}</span>
          ),
        }}
        className="vp-title"
      />

      {content && (
        <Typography
          variant="heading4"
          color={contentColor}
          tag="h4"
          intlKey={content}
          strong
          gutterBottom={1.5}
          css={css`span {color: ${nuDSColor('primary')};}`}
          intlValues={{
            rate,
            span: msg => (
              <span>{msg}</span>
            ),
          }}
          className="vp-subtitle"
        />
      )}
    </Box>
    { hasDivider && <Divider />}
    <Box
      gridArea="picture"
      display="flex"
      alignSelf={{
        xs: 'center',
        lg: 'center',
      }}
      justifyContent="center"
      padding={{ xs: '3rem 3rem 0rem', md: '6rem', lg: '6rem' }}
      className="vp-image-container"
    >
      <Image
        alt={alt}
        srcSet={heroImages}
        width={imageSize}
        margin="0 auto"
      />
    </Box>
  </Box>
);

ProductHero.defaultProps = {
  content: '',
  contentColor: '',
  imageSize: '',
  hasDivider: false,
  styleOverride: '',
  rate: 12,
};

ProductHero.propTypes = {
  alt: PropTypes.string.isRequired,
  backGroungColor: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentColor: PropTypes.string,
  gridLargeOrder: PropTypes.string.isRequired,
  hasDivider: PropTypes.bool,
  heroImages: PropTypes.shape({
    lg: PropTypes.string.isRequired,
    md: PropTypes.string.isRequired,
    xs: PropTypes.string.isRequired,
  }).isRequired,
  imageSize: PropTypes.string,
  rate: PropTypes.number,
  styleOverride: PropTypes.string,
  title: PropTypes.string.isRequired,
  titleColor: PropTypes.string.isRequired,
};

export default ProductHero;
