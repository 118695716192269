import styled from 'styled-components';
import { tablet768, desktop1024, mobile480 } from '@nubank/nuds-web/styles/breakpoints';

export const HeroPicture = styled.picture`
  display: block;

  img {
    height: auto;
    width: 100%;

    ${tablet768`
      height: 768px;
      width: 409px;
      object-fit: cover;
      flex-shrink: 0;
    `}

    ${desktop1024`
      width: 711px;
      height: 785px;
      margin-right: 0px;
      margin-left: auto;
    `}

    ${mobile480`
      
    `}
  }

  ${desktop1024`
    order: 1;
    flex-basis: 50%;
  `}
  
`;

HeroPicture.displayName = 'HeroPicture';
