import React, {
  createContext, useContext,
} from 'react';
import PropTypes from 'prop-types';

export const CarouselContext = createContext({
  prevStep: () => {},
  nextStep: () => {},
  goToStep: () => {},
  currentStep: null,
});

export const useStepControls = () => {
  const {
    prevStep,
    nextStep,
    goToStep,
  } = useContext(CarouselContext);

  return {
    prevStep,
    nextStep,
    goToStep,
  };
};

export const CarouselContextProvider = ({ children, value }) => (
  <CarouselContext.Provider value={{ ...value }}>
    {children}
  </CarouselContext.Provider>
);

CarouselContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  value: PropTypes.shape({
    currentStep: PropTypes.number,
    gotToStep: PropTypes.func,
    nextStep: PropTypes.func,
    prevStep: PropTypes.func,
  }).isRequired,
};

