import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useSwipeable } from 'react-swipeable';

import { CarouselContext } from '../../CarouselContext';

import { Wrapper } from './styles/Wrapper';
import { CarouselContainer } from './styles/CarouselContainer';
import { CarouselSlot } from './styles/CarouselSlot';

const getSwipeableCSSSlideOrder = ({ index, swipeableStepIndex, numItems }) => {
  const baseOrder = 0;
  const indexToCSSOrder = index - swipeableStepIndex;
  const isIndexToCSSOrderNegative = indexToCSSOrder < baseOrder;

  if (isIndexToCSSOrderNegative) {
    const positiveIndexToCSSOrder = numItems - Math.abs(indexToCSSOrder);
    return positiveIndexToCSSOrder;
  }

  return indexToCSSOrder;
};

const CarouselSlider = ({
  className,
  cardWidth,
}) => {
  const {
    items,
    prevStep,
    nextStep,
    state,
    transitionDurationInMs,
  } = useContext(CarouselContext);

  const handlers = useSwipeable({
    onSwipedLeft: nextStep,
    onSwipedRight: prevStep,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  return (
    <div className={className}>
      <Wrapper cardWidth={cardWidth} {...handlers}>
        <CarouselContainer
          slideToDirection={state.slideToDirection}
          sliding={state.sliding}
          cardWidth={cardWidth}
          transitionDurationInMs={transitionDurationInMs}
        >
          {items.map((child, index) => (
            <CarouselSlot
              key={`${child.key}-slot`}
              flex={`1 0 ${cardWidth}%`}
              order={getSwipeableCSSSlideOrder({
                index,
                swipeableStepIndex: state.swipeableStepIndex,
                numItems: items.length,
              })}
            >
              {child}
            </CarouselSlot>
          ))}
        </CarouselContainer>
      </Wrapper>
    </div>
  );
};

CarouselSlider.defaultProps = {
  className: undefined,
  cardWidth: 100,
};

CarouselSlider.propTypes = {
  cardWidth: PropTypes.number,
  className: PropTypes.string,
};

export default CarouselSlider;
