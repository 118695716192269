import styled, { css } from 'styled-components';
import { nuDSColor, spacing } from '@nubank/nuds-web/styles/themeUtils';

export const ControlIcon = styled.span`
  display: inline-block;
  border-radius: ${spacing('1x')};
  height:${spacing('2x')};
  margin: ${spacing('2x')};
  transition: width 0.5s, background-color 0.5s;
  cursor: pointer;

  ${({ isSelected, colorSelected, colorUnselected }) => (
    isSelected
      ? css`
        background-color: ${nuDSColor(colorSelected)};
        width: 1rem;
      `
      : css`
        background-color: ${nuDSColor(...colorUnselected)};
        width: 0.5rem;
      `)}
`;

ControlIcon.displayName = 'ControlIcon';
